<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-row class="pa-0 ma-0">
      <v-col cols="12" md="8">
        <h1 class="mb-1">OTP de verificación</h1>
      </v-col>
      <v-col cols="12" md="4">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value="date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="date"
              label="Filtrar por fecha"
              prepend-icon="fa-calendar-alt"
              readonly
              filled
              rounded
              hide-details
              :disabled="loading"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            range
            :title-date-format="title"
            scrollable
            :max="today"
          >
            <v-row no-gutters style="flex-wrap: nowrap">
              <v-btn
                class="flex-grow-1 flex-shrink-0"
                large
                rounded
                outlined
                color="primary"
                @click="modal = false"
                >Cancelar</v-btn
              >
              <v-btn
                class="flex-grow-1 flex-shrink-0"
                large
                rounded
                color="primary"
                @click="
                  $refs.dialog.save(date);
                  updateDateFilter();
                "
              >
                Filtrar
              </v-btn>
            </v-row>
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-data-table
          :headers="headers"
          :items="otp"
          :search="$store.state.search"
          :items-per-page="15"
          :loading="loading"
          sort-by="date"
          class="mt-5"
          sort-desc
          item-key=".key"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:[`item.date`]="{ item }">
            <span class>{{ item.date | filterDate }}</span>
          </template>

          <template v-slot:[`item.smsCode`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-chip
                    color="#989898"
                    dark
                    v-on="on"
                    @click="copyToClipboard(item)"
                    style="
                      text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
                      cursor: pointer;
                    "
                    >{{ item.smsCode }}
                  </v-chip>
                </template>
                <span>Clic para cambiar el estado de la orden</span>
              </v-tooltip>
            </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import Lottie from "@/components/Lottie";
// import lottieSettings from "@/mixins/lottieMixin";
// import axios from "axios";
// import AddMessage from "./AddMessage";
import { db } from "@/firebase";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default {
  name: "OTP",

  data() {
    return {
      loading: true,
      modal: false,
      date: [
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(6, "hours")
          .toISOString()
          .substr(0, 10)}`,
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(6, "hours")
          .toISOString()
          .substr(0, 10)}`,
      ],
      snackbar: false,
      snackbarText: "",
      otp: [],
      headers: [
        {
          text: "Número de telefono",
          value: "phone",
          class: "nowrap",
          width: "250px",
        },
        {
          text: "Código",
          value: "smsCode",
        },
        {
          text: "Proveedor",
          value: "provider",
          class: "nowrap",
        },

        {
          text: "Fecha de envío",
          value: "date",
          class: "nowrap",
        },
      ],
    };
  },
  filters: {
    filterDate: function (timestamp) {
      if (!timestamp) return "n/a";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MM/YYYY, hh:mm A ");
    },
  },
  computed: {
    today() {
      return new Date().toISOString().substr(0, 10);
    },

    getOtp() {
        return this.otp.map( e => {
            return {
                smsCode : e.smsCode,
                date : e.date,
                provider : e.provider,
                phone : `${e.phone.substr(0, 3)}****${e.phone.substr(7, e.phone.length)}`
            }
        })
    }
  },

  async mounted() {
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;
    this.$store.commit("setSearchTerm", "mensajes");

    this.getMessages();
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text.smsCode).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },

    async getMessages() {
      if (this.date[0] && this.date[1]) {
        this.loading = true;
        await this.$binding(
          "otp",
          db
            .collection("smsCodes")
            .where(
              "date",
              ">",
              moment(this.date[0], "YYYY-MM-DD").startOf("day").toDate()
            )
            .where(
              "date",
              "<",
              moment(this.date[1], "YYYY-MM-DD").endOf("day").toDate()
            )
        );

        this.loading = false;
      }
    },

    updateDateFilter() {
      if (this.date.length > 1) {
        if (moment(this.date[0], "YYYY-MM-DD") > new Date(this.date[1])) {
          this.date = [this.date[1], this.date[0]];
        }
      } else {
        this.date = [this.date[0], this.date[0]];
      }

      this.getMessages();
    },

    
    title() {
      return "Selecciona las fechas a filtrar";
    },
  },
};
</script>
